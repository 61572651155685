<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">活动名称: </label>
				<el-input v-model="searchKey" clearable style='width:300px' placeholder="活动名称"></el-input>
			</div>
			<!-- 活动状态 -->
			<!-- <div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="status" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in statusOptions"	:key="item.value" :label="item.label"	:value="item.value">
					</el-option>
				</el-select>
			</div> -->
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addCouponBack'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleTableEdit({id:0},0)">添加活动</el-button> 
				</button-permissions>
				<button-permissions :datas="'delCouponBack'">
					<el-button type="warning" style="margin-left:30px;width:100px;" @click="handleDelCoupon">批量删除</el-button> 
				</button-permissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table	:data="tableData" style="width: 100%" v-loading='loading' :row-key="getRowKeys" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="actName"	label="活动名称"></el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						{{statusType[scope.row.actStatus]}}
					</template>
				</el-table-column>
				<el-table-column prop="begTime" label="开始时间"></el-table-column>
				<el-table-column prop="endTime" label="结束时间"></el-table-column>
				<el-table-column	label="操作" width="300px">
					<template slot-scope="scope">
						<div class="table-button" @click="handleTableEdit(scope.row,1)">查看</div>
						<button-permissions :datas="'editCouponBack'">
							<div class="table-button" @click="handleTableEdit(scope.row,2)" >编辑</div>
						</button-permissions>
						<button-permissions :datas="'deleteCouponBack'">
							<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scope.row.id)">删除</div>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			:total="page.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>	
import {
	orderPolitefindAll,
	orderPolitedelById
} from '@/api/wyUsedInterface.js'
import buttonPermissions from '@/components/buttonPermissions';
	export default{
		components: {
			buttonPermissions
		},
		data(){
			return{
				loading:false,
				statusType:['未开始','进行中','已结束'],
				tableData:[],
				searchKey:'',
				status:null,
				statusOptions:[
					{
						label:'未开始',
						value:0
					},
					{
						label:'进行中',
						value:1,
					},
					{
						label:'已结束',
						value:2
					}
				],
				page:{
					total:0,
					current:1,
					size:20
				},
				multipleSelection:[],
			}
		},
		created(){
			this.getList();
		},
    methods: {
				// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						pageNo: this.page.current, 
						pageSize: this.page.size,
						actName:this.searchKey,
					}
					let result = await orderPolitefindAll(data);

					this.page.total = result.data.total;
					this.tableData = result.data.records || [];

				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			handleFilter(){
				this.getList()
				// this.$router.push({ path: '/market/coupon/couponGiveRecord'})
			},
			// table编辑，查看
			handleTableEdit(val,type){
				//type 0 添加  1 查看  2编辑
				this.$router.push({ path: '/market/orderCouponBack/editCouponBackDetail',query:{id:val.id,type:type}})
			},
				// table删除
			handleDelCoupon(){
				var ids = [];
				this.multipleSelection.map(item=>{
					ids.push(item.id);
					return item;
				})
				this.$confirm('是否确认删除所选活动？', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					let result = await orderPolitedelById(ids)
					if(result.success){
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						this.getList()
					}else{
						this.$message({showClose: true,
							type: 'info',
							message: result.alertMsg
						});
					}

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
				})
			},
				// table删除
			handleTableDelete(id){
				this.$confirm('是否确认删除活动？删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					let result = await orderPolitedelById([id])
					if(result.success){
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						this.getList()
					}else{
						this.$message({showClose: true,
							type: 'info',
							message: result.alertMsg
						});
					}

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
				})
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
			getRowKeys (row) {
				return row.id;
			},
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
    }
	}
</script>

<style  lang="less" scoped>
.app-container{
	background-color: #fff;
}
	.table-button{
		color: #409EFF;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}
	.dialog{
		width: 100%;
		/deep/.el-dialog__header {
				border-bottom: 1px solid #ddd;
		}
		.content{
			padding: 0 30px;
			display: flex;
			flex-direction: row;
			.right{
				margin-left: 20px;
			}
			.text{
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}
		.button{
			margin: 0 auto;
			width: 200px;
		}
	}
	.data-dialog{
		.value{
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}
		.item{
			color: #666;
			margin-top: 10px;
		}
	}

</style>
